import { merge, reduce } from "lodash-es";
import { createTheme } from "@material-ui/core/styles";
import { staticUrl } from "./utils";

const bgPalmTree = staticUrl(require("../static/img/theme-bg-palm-dark.svg"));
const bgPalmLeaf = staticUrl(require("../static/img/theme-bg-palm-leaf.svg"));

/* Atlas guru palette based on:
 * https://atlasguru.sharepoint.com/sites/websiteteam/Shared%20Documents/latest_design/Atlas_Guru_styleguide112118.pdf
 * TODO: This long file must be refactored and divided in sections into `theme/`
 * Sections:
 * - Config
 * - Utils
 * - Typography
 * - MuiTheme
 * - Context Setup
 */
const atlasGuruTheme = {
  aspectRatio: {
    normal: "100%", // 1:1
    standard: "74%", // 4:3
    wide: "56.25%", // 16:9
    heroMobile: "170.77%", // 65:111 - custom mobile hero dimensions
  },
  breakpoints: {
    xs: 0,
    xsm: 480,
    sm: 680,
    md: 960,
    lg: 1192,
    xl: 1920,
  },
  fonts: [
    "'Source Sans Pro', sans-serif",
    "'Yeseva One', sans-serif",
    "Courier New, Courier New, monospace",
    "Georgia, serif",
  ],
  fontWeights: {
    bold: 700,
    normal: 400,
    light: 300,
  },
  fontSizes: {
    body1: { xs: 17, lg: 18 },
    body2: { xs: 17, lg: 16 },
    button: { xs: 13, sm: 14 },
    teaser: { xs: 30, sm: 40, md: 60 },
    jumbo: { xs: 60, lg: 100 },
    chip: { xs: 14 },
    caption: { xs: 12, sm: 14 },
    // caption2: { xs: 13 },
    // caption3: { xs: 15, sm: 16, lg: 17 },
    // navigation: { xs: 14 },
    h1: { xs: 30, sm: 34, lg: 38 },
    h2: { xs: 24, sm: 27, lg: 30 },
    h3: { xs: 20, sm: 22, lg: 24 },
    h4: { xs: 16, sm: 20 },
    h5: { xs: 14, sm: 15 },
    h6: { xs: 16 },
    subtitle1: { xs: 18, sm: 20 },
    subtitle2: { xs: 16 },
    overline: { xs: 11, sm: 13 },
  },
  palette: {
    alert: "#D52230",
    alertDark: "#A51924",
    black: "#091C29",
    boxShadow: "0,0,0", // TODO: use hexToRbgA
    boxShadowHighlight: "204,171,60", // TODO: use hexToRbgA
    greyLighter40: "#FBFBFB",
    greyLighter: "#F7F7F7",
    greyLight: "#F0F0EC",
    greySemi: "#9A9A86",
    greySemiLight: "#E2E2DA",
    greySemiLightSoft: "#E0E0E0",
    greyLightSoft: "#BABAAC",
    greyLightMid: "#BEBEBE",
    greyLightHard: "#AFAFAF",
    grey: "#C4C4B8",
    greyMid: "#C4C4B8",
    greyMidHard: "#535342",
    greyHard: "#6C6C6C",
    greyMidDark: "#535340",
    greyDark: "#474840",
    highlight: "#C7AB50",
    highlightLight: "#e6d394",
    main: "#203457",
    mainLight: "#3D89FB",
    mainLighter: "#D2E1F8",
    mainLightest: "#F1F7FF",
    mainDark: "#091C29",
    secondary: "#729B56",
    google: "#DB4437",
    facebook: "#3B5998",
    white: "#FFFFFF",
  },
  spacing: (y = 1) => 8 * y,
};

// Transform font size from px to em
// const convertToRelative = (px, base) => `${px / base}em`;

// Convert from hexadecimal to rgba
// TODO: make it works correctly
const hexToRgbA = (hex, opacity = 1) => {
  let c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    c = `0x ${c.join("")}`;

    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )}, ${opacity})`;
  }

  throw new Error("Bad Hex");
};

// Global values for box-shadow
const boxShadow = ({
  color = atlasGuruTheme.palette.boxShadow,
  opacity = 0.08,
  values = "0px 2px 6px",
} = {}) => `${values} rgba(${color}, ${opacity})`; // TODO: use hexToRbgA for color

const boxShadowV2 = ({
  color = atlasGuruTheme.palette.boxShadow,
  opacity = 0.08,
  values = "0px 8px 16px",
} = {}) => `${values} rgba(${color}, ${opacity})`;

// Generate responsives font sizes from a specified list of tags [h1, h2, h3, etc]
const generateResponsiveFonts = (breakpoints, fontSizes) =>
  reduce(
    fontSizes,
    (elemAcc, sizes, elem) => ({
      ...elemAcc,
      // generate style for element and corresponding mediaquery
      [elem]: reduce(
        sizes,
        (sizesAcc, size, breakpoint) => ({
          ...sizesAcc,
          [`@media (min-width: ${breakpoints[breakpoint]}px)`]: {
            fontSize: size, // convertToRelative(size, fontSizes.body1[breakpoint])
          },
        }),
        {}
      ),
    }),
    {}
  );

// Font config for material-ui theme
const typographyConfig = merge(
  generateResponsiveFonts(atlasGuruTheme.breakpoints, atlasGuruTheme.fontSizes),
  {
    body1: {
      color: atlasGuruTheme.palette.greyMidDark,
      fontFamily: atlasGuruTheme.fonts[3],
      lineHeight: 1.7,
    },
    body2: {
      color: atlasGuruTheme.palette.greyMidDark,
      fontFamily: atlasGuruTheme.fonts[3],
      lineHeight: 1.7,
    },
    button: {
      fontFamily: atlasGuruTheme.fonts[0],
      letterSpacing: "0.015em",
    },
    teaser: {},
    jumbo: {
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.bold,
    },
    chip: {},
    caption: {
      letterSpacing: "0.015em",
    },
    // caption2: {
    //  color: atlasGuruTheme.palette.black,
    // },
    // caption3: {
    //   fontStyle: "italic",
    //   color: atlasGuruTheme.palette.greyMidDark,
    // },
    // navigation: {
    //   fontFamily: atlasGuruTheme.fonts[0],
    //   fontWeight: atlasGuruTheme.fontWeights.bold,
    //   letterSpacing: 1,
    //   textTransform: "uppercase",
    // },
    h1: {
      color: atlasGuruTheme.palette.black,
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.bold,
      letterSpacing: 0,
      lineHeight:
        (atlasGuruTheme.fontSizes.h1.xs + 4) / atlasGuruTheme.fontSizes.h1.xs,
    },
    h2: {
      color: atlasGuruTheme.palette.black,
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.bold,
      letterSpacing: 0,
      lineHeight:
        (atlasGuruTheme.fontSizes.h2.xs + 4) / atlasGuruTheme.fontSizes.h2.xs,
    },
    h3: {
      color: atlasGuruTheme.palette.black,
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.bold,
      letterSpacing: 0,
      lineHeight:
        (atlasGuruTheme.fontSizes.h3.xs + 4) / atlasGuruTheme.fontSizes.h3.xs,
    },
    h4: {
      color: atlasGuruTheme.palette.black,
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.bold,
      letterSpacing: 0,
      lineHeight:
        (atlasGuruTheme.fontSizes.h4.xs + 2) / atlasGuruTheme.fontSizes.h4.xs,
    },
    h5: {
      color: atlasGuruTheme.palette.black,
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.bold,
      letterSpacing: 0,
      lineHeight:
        (atlasGuruTheme.fontSizes.h5.xs + 2) / atlasGuruTheme.fontSizes.h5.xs,
    },
    h6: {
      color: atlasGuruTheme.palette.greyMidHard,
      fontFamily: atlasGuruTheme.fonts[1],
      fontWeight: atlasGuruTheme.fontWeights.normal,
      lineHeight:
        (atlasGuruTheme.fontSizes.h6.xs + 2) / atlasGuruTheme.fontSizes.h6.xs,
    },
    subtitle1: {
      color: atlasGuruTheme.palette.greyMidDark,
      fontFamily: atlasGuruTheme.fonts[1],
      lineHeight: 1.2,
      letterSpacing: "3px",
      textTransform: "uppercase",
    },
    subtitle2: {
      color: atlasGuruTheme.palette.greyMidHard,
      fontFamily: atlasGuruTheme.fonts[0],
      fontWeight: atlasGuruTheme.fontWeights.normal,
    },
    overline: {
      color: atlasGuruTheme.palette.black,
      fontFamily: atlasGuruTheme.fonts[0],
      textTransform: "uppercase",
    },
  }
);

// Material-ui theme overwrites
const theme = createTheme({
  spacing: atlasGuruTheme.spacing,

  breakpoints: {
    values: atlasGuruTheme.breakpoints,
  },

  palette: {
    common: {
      black: atlasGuruTheme.palette.black,
      highlight: atlasGuruTheme.palette.highlight,
      highlightLight: atlasGuruTheme.palette.highlightLight,
      facebook: atlasGuruTheme.palette.facebook,
      google: atlasGuruTheme.palette.google,
      white: atlasGuruTheme.palette.white,
    },
    error: {
      main: atlasGuruTheme.palette.alert,
      dark: atlasGuruTheme.palette.alertDark,
    },
    primary: {
      lightest: atlasGuruTheme.palette.mainLightest,
      lighter: atlasGuruTheme.palette.mainLighter,
      light: atlasGuruTheme.palette.mainLight,
      main: atlasGuruTheme.palette.main,
      dark: atlasGuruTheme.palette.mainDark,
    },
    secondary: {
      main: atlasGuruTheme.palette.secondary,
    },
    grey: {
      40: atlasGuruTheme.palette.greyLighter40,
      50: atlasGuruTheme.palette.greyLighter,
      100: atlasGuruTheme.palette.greyLight,
      150: atlasGuruTheme.palette.greySemi,
      200: atlasGuruTheme.palette.greySemiLight,
      250: atlasGuruTheme.palette.greySemiLightSoft,
      300: atlasGuruTheme.palette.greyLightSoft,
      400: atlasGuruTheme.palette.greyLightMid,
      450: atlasGuruTheme.palette.greyMidHard,
      500: atlasGuruTheme.palette.greyLightHard,
      600: atlasGuruTheme.palette.grey,
      700: atlasGuruTheme.palette.greyHard,
      750: atlasGuruTheme.palette.greyMidDark,
      800: atlasGuruTheme.palette.greyDark,
    },
    background: {
      default: atlasGuruTheme.palette.mainDark,
    },
  },

  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginBottom: "0.3em",
        marginTop: "0.6em",
      },
      marginDense: {
        marginTop: atlasGuruTheme.spacing() * 1.5,
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        transition: "color 0.3 ease-in-out",

        "&:hover": {
          backgroundColor: "transparent",
          color: atlasGuruTheme.palette.highlight,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: atlasGuruTheme.fontSizes.body1.xs,

        "&$error": {
          "& .icon > svg": {
            color: atlasGuruTheme.palette.alert,
          },
        },
      },

      input: {
        backgroundColor: atlasGuruTheme.palette.white,
        padding: "0.6em",

        "&:-webkit-autofill": {
          "&, &:hover, &:focus, &:active": {
            transition: "background-color 5000s ease-in-out 0s",
            boxShadow: "0 0 0px 1000px transparent inset",
            textFillColor: atlasGuruTheme.palette.greyHard,
          },
        },

        "&:-webkit-outer-spin-button, &:-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },

        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
      },

      inputMarginDense: {
        paddingBottom: atlasGuruTheme.spacing() * 2,
        paddingTop: atlasGuruTheme.spacing() * 2,
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: atlasGuruTheme.palette.mainLight,
        },

        "&$focused $notchedOutline": {
          borderWidth: 1,
        },
      },

      input: {
        padding: "0.6em",
      },

      inputMarginDense: {
        paddingBottom: atlasGuruTheme.spacing() * 2,
        paddingTop: atlasGuruTheme.spacing() * 2,
      },

      focused: {},

      notchedOutline: {},
    },
    MuiInputLabel: {
      root: {
        width: "calc(100% - 24px)",
      },

      outlined: {
        ...typographyConfig.caption,
        // move to variant of ui/Input
        textAlign: "center",
        transform: "translate(14px, 12px) scale(1)",

        "&$marginDense": {
          // ...typographyConfig.caption3,
          fontStyle: "normal",
          textAlign: "left",
        },

        "&$shrink": {
          width: "auto",
        },
      },

      marginDense: {},
      shrink: {},
    },
    MuiMenuItem: {
      root: {
        color: atlasGuruTheme.palette.greyMidDark,
        // fontSize: atlasGuruTheme.fontSizes.h6.xs,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
    MuiPrivateTabScrollButton: {
      root: {
        width: atlasGuruTheme.spacing(3),

        "&:first-child": {
          marginLeft: atlasGuruTheme.spacing(-1),
        },

        "&:last-child": {
          marginRight: atlasGuruTheme.spacing(-1),
        },
      },
    },
  },

  gutterFinger: {
    padding: `1em ${atlasGuruTheme.spacing(3)}px`,

    [`@media (min-width: ${atlasGuruTheme.breakpoints.sm}px)`]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },

    [`@media (min-width: ${atlasGuruTheme.breakpoints.md}px)`]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
    },
  },

  typography: {
    fontSize: atlasGuruTheme.fontSizes.body1.xs,
    fontFamily: atlasGuruTheme.fonts[0],
    fontWeightLight: atlasGuruTheme.fontWeights.light,
    fontWeightRegular: atlasGuruTheme.fontWeights.normal,
    fontWeightMedium: atlasGuruTheme.fontWeights.bold,
    useNextVariants: true,
    ...typographyConfig,
  },

  /* Start - Custom AtlasGuru code */
  // common properties and utilities
  gutter: atlasGuruTheme.spacing(3),
  modalSize: 820,
  atlasGuru: {
    aspectRatio: atlasGuruTheme.aspectRatio,
    bgPath: atlasGuruTheme.bgPath,
    boxShadow,
    boxShadowV2,
    boxShadowHighlight: atlasGuruTheme.palette.boxShadowHighlight,
    dividerContrast: "rgba(255,255,255,0.12)",
    fonts: atlasGuruTheme.fonts,
    fontSizes: atlasGuruTheme.fontSizes,
    hexToRgbA,
    primaryWithOpacityAt: (opacity) => `rgba(27, 52, 90, 0.${opacity})`, // TODO: use hexToRbgA
  },
  // atlasguru backgrounds receipes
  backgrounds: {
    palmLeaf: {
      background: "no-repeat 0 0",
      backgroundImage: `url(${bgPalmLeaf})`,
      backgroundSize: "100%",
      content: '""',
      display: "block",
      opacity: 0.1,
      position: "absolute",
    },

    palmTree: {
      background: "no-repeat 50% 100%",
      backgroundImage: `url(${bgPalmTree})`,
      backgroundSize: "85px",
      content: '""',
      height: 85,
      width: 43,
    },

    preloader: {
      animationDuration: "4s",
      animationFillMode: "forwards",
      animationIterationCount: "infinite",
      animationName: "placeHolderShimmer",
      animationTimingFunction: "linear",
      background:
        "linear-gradient(to right, #f3f3f3 0%, #d9d9d9 20%, #f3f3f3 30%)",
      backgroundSize: "100em 100em",
    },
  },
  /* End - Custom AtlasGuru code */
});

export default theme;
